
function AdminHeader({ handleTabs, tabs }) {
    const activeId = window.location.pathname;
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-brand" href="#">PrairieJobs</a>
                    <a className="d-lg-none d-md-none" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                        <span className="material-symbols-outlined">
                            more_vert
                        </span>
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav mx-auto">
                            <span className="text-center me-lg-5 mb-2 mb-lg-0 mb-md-0">
                                <input className="form-check-input" type="checkbox" value="" id="employerTab" onChange={() => { handleTabs({ employerTab: !tabs.employerTab }) }} checked={tabs.employerTab} />
                                <label className="form-check-label" htmlFor="employerTab">
                                    Employer
                                </label>
                            </span>
                            <span className="text-center me-lg-5 mb-2 mb-lg-0 mb-md-0">
                                <input className="form-check-input" type="checkbox" value="" id="employeeTab" onChange={() => { handleTabs({ employeeTab: !tabs.employeeTab }) }} checked={tabs.employeeTab} />
                                <label className="form-check-label" htmlFor="employeeTab">
                                    Employee
                                </label>
                            </span>
                            <span className="text-center me-lg-5 mb-2 mb-lg-0 mb-md-0">
                                <input className="form-check-input" type="checkbox" value="" id="employedTab" onChange={() => { handleTabs({ employedTab: !tabs.employedTab }) }} checked={tabs.employedTab} />
                                <label className="form-check-label" htmlFor="employedTab">
                                    Employed
                                </label>
                            </span>
                        </div>
                        <div className="navbar-nav ms-auto">
                            <a className="navbar-brand bg-gray-100 p-2 rounded-md hover:bg-gray-200" href="/">Logout</a>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Navigation</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <nav id="navbar-example3" className=" flex-column align-items-stretch pe-4 ">
                        <nav className="nav nav-pills flex-column ">
                            <a className={`nav-link ${activeId == '/admin' ? 'active' : ''}`} href="/admin">Employment Board</a>
                            <a className={`nav-link ${activeId == '/time' ? 'active' : ''}`} href="/time">Time Card</a>
                            <a className={`nav-link ${activeId == '/user-list' ? 'active' : ''}`} href="/user-list">User List</a>
                        </nav>
                    </nav>
                </div>
            </div>

        </>
    )
}

export default AdminHeader