export const getCurrentWeek = (offset) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + offset * 7);
    const weekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6);
    
    const format = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    
    return {
      week_start: format(weekStart),
      week_end: format(weekEnd),
    };
  };