import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import ContactUs from "../../assets/contact-us.png";

const Contact = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [attachment, setAttachment] = React.useState(null);
  const [messageSent, setMessageSent] = React.useState(null);

  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("message", message);
    formData.append("inquiryType", subject);
    if (attachment) {
      formData.append("attachment", attachment);
    }

    try {
      const response = await axios.post("/send", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response);
      setMessageSent("Your message has been sent successfully!");
    } catch (err) {
      console.error(err);
      setMessageSent("Failed to send your message. Please try again.");
    }
  };

  useEffect(() => {
    console.log(firstName, lastName, email, phoneNumber, message, subject);
  }, [firstName, lastName, email, phoneNumber, message, subject]);

  return (
    <div style={{ height: "100vh" }} className="d-flex flex-column min-vh-100">
      <Header />
      <div className="container d-flex flex-wrap justify-content-center align-items-center mb-5 pt-5 mt-3">
        <div className="col-lg-6 pe-5 d-flex justify-content-end d-none d-lg-flex">
          <img
            src={ContactUs}
            alt="cover"
            className="img-fluid rounded-md"
            style={{ maxHeight: "80vh", width: "100%" }}
          />
        </div>
        <div className="col-lg-6 col-md-12 py-8 ps-lg-5">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-black">
            Contact Us
          </h2>
          <p className="mb-8 font-light text-center text-gray-900 sm:text-xl">
            Looking for work? Looking for talent? Send us a message and we'll
            reach out with the next steps.
          </p>

          {messageSent && (
            <div className="mb-4 text-lg text-center text-success">
              {messageSent}
            </div>
          )}

          {!messageSent && (
            <form action="#" className="mb-4" onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-lg-6">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    id="firstName"
                    className="form-control"
                    placeholder="John"
                    required
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    id="lastName"
                    className="form-control"
                    placeholder="Doe"
                    required
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="name@email.com"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="tel"
                  id="phoneNumber"
                  className="form-control"
                  placeholder="306-555-5555"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subject" className="form-label">
                  Subject
                </label>
                <select
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  id="subject"
                  className="form-select"
                  required
                >
                  <option value="">Please select an option</option>
                  <option value="generalinquiry">General Inquiry</option>
                  <option value="findwork">Find Work</option>
                  <option value="findtalent">Find Talent</option>
                  <option value="technicalsupport">Technical Support</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Your Message
                </label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id="message"
                  rows="6"
                  className="form-control"
                  placeholder="Leave a comment..."
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="attachment" className="form-label">
                  Attachment (PDF, Image, or Text)
                </label>
                <input
                  type="file"
                  id="attachment"
                  className="form-control"
                  accept=".pdf, .jpg, .png, .txt"
                  onChange={handleFileChange}
                />
              </div>
              <div className="d-flex justify-content-lg-start justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-lg-0 w-100"
                >
                  Send Message
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
