import axios from "axios";
export const fetchTimeCards = async (employeeId, page) => {
  try {
    const response = await axios.get(`/api/timecards/getEmployee/${employeeId}`, {
      params: { page: page },
      headers: { Authorization: localStorage.getItem("token") },
    });

    const { data } = response.data;
    const rowdata = data.map((element) => {
      const formatWeek = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          timeZone: "UTC",
        });
      };

      return {
        id: element._id,
        week: `${formatWeek(element.week_start)} - ${formatWeek(element.week_end)}`,
        Sun: element.daily_hours[0] ? `${element.daily_hours[0].start_time} - ${element.daily_hours[0].end_time}` : "OFF",
        Mon: element.daily_hours[1] ? `${element.daily_hours[1].start_time} - ${element.daily_hours[1].end_time}` : "OFF",
        Tue: element.daily_hours[2] ? `${element.daily_hours[2].start_time} - ${element.daily_hours[2].end_time}` : "OFF",
        Wed: element.daily_hours[3] ? `${element.daily_hours[3].start_time} - ${element.daily_hours[3].end_time}` : "OFF",
        Thu: element.daily_hours[4] ? `${element.daily_hours[4].start_time} - ${element.daily_hours[4].end_time}` : "OFF",
        Fri: element.daily_hours[5] ? `${element.daily_hours[5].start_time} - ${element.daily_hours[5].end_time}` : "OFF",
        Sat: element.daily_hours[6] ? `${element.daily_hours[6].start_time} - ${element.daily_hours[6].end_time}` : "OFF",
      };
    });

    return rowdata;
  } catch (error) {
    console.error("Error fetching time cards:", error);
    throw error;
  }
};
