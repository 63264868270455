import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import { getCurrentWeek } from "../utils/dateUtils";
import PortalHeader from "../components/PortalHeader";
import Dropdown from "../components/employer/Dropdown";
import Calendar from "../components/employee/Calendar";

const generateWeekDays = (weekStart) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const startDate = new Date(weekStart);

  return days.map((day, index) => {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + index);
    return {
      day,
      date: currentDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        timeZone: "UTC",
      }),
    };
  });
};

function Row(props) {
  const { timecard } = props;
  const [open, setOpen] = useState(true);

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell colSpan={0}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell  colSpan={0}  scope="row">
          {timecard.employee_name}
        </TableCell>
        <TableCell onClick={() => {
          if (!open) {
            setOpen(true);
          }
        }} align="right">
        </TableCell>
      </TableRow>
      {timecard.time_cards.map((timecard, index)=>(
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="timecard-details">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={5} className="fw-bold">
                      Week of {new Date(timecard.week_start).toLocaleDateString()} -{" "}
                      {new Date(timecard.week_end).toLocaleDateString()}
                    </TableCell>
                    <TableCell colSpan={2}>
                    <Dropdown id={timecard._id} initialstatus={timecard.status} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {generateWeekDays(timecard.week_start).map((day, index) => (
                      <TableCell key={index}>
                        {day.day}, {day.date}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {timecard.daily_hours.map((day, index) => (
                      <TableCell key={index}>
                        {day.start_time} - {day.end_time}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      ))}

    </Fragment>
  );
}

Row.propTypes = {
  timecard: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    employee_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    week_start: PropTypes.string.isRequired,
    week_end: PropTypes.string.isRequired,
    daily_hours: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string.isRequired,
        start_time: PropTypes.string.isRequired,
        end_time: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

function Employer() {
  const employerId = JSON.parse(localStorage.getItem("user"))._id;
  const [timecards, setTimecards] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const currentWeek = getCurrentWeek(0);

  async function fetchTimeCards() {
    try {
      console.log(employerId);
      const response = await axios.get(
        `api/timecards/getEmployerTimecards/${employerId}`,
        {
          params: {
            week_start: currentWeek.week_start
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data.data);
      setTimecards(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchTimeCards();
  }, []);

  const handleClickCal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ height: "90vh" }}>
      <PortalHeader />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-12 w-100 text-lg-end mt-2 mt-lg-0 mt-md-0">
            <span className="btn-group me-3" role="group">
              <button type="button" className="btn btn-outline-primary">
                This Week
              </button>
              <button type="button" className="btn btn-outline-primary d-flex align-items-center">
                <span className="material-symbols-outlined">arrow_back_ios</span>
              </button>
              <button type="button" className="btn btn-outline-primary d-flex align-items-center">
                <span className="material-symbols-outlined">arrow_forward_ios</span>
              </button>
              <button type="button" className="btn btn-outline-primary" onClick={handleClickCal}>
                <span className="material-symbols-outlined cal">calendar_month</span>
              </button>
              <Calendar anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            </span>
          </div>
        </div>

        <div className="row mt-5">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} className="fw-bold">Week of</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timecards.map((timecard) => (
                  <Row key={timecard._id} timecard={timecard} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default Employer;