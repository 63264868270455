import { useState, useContext } from "react";
import Navbar from "../components/Header";
import Logo from "../assets/image.png";
import axios from "axios";
import { ContextProvider } from "../App";
import Footer from "../components/Footer";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, setUser } = useContext(ContextProvider);
  const [message, setMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = { email, password };
    try {
      const response = await axios.post("/api/auth/login", data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log(response);

      if (response.status === 200) {
        console.log("redirected");
        localStorage.setItem("user", JSON.stringify(response.data.user));
        window.location.href = "/admin";
      }
      setMessage("");
      localStorage.setItem("token", response.data.token);
    } catch (error) {
      const message = error.response.data.message
      setMessage(message);
      console.log("message: " + message);
    }
  };

  return (
    <div style={{ height: "100vh" }} className="d-flex flex-column min-vh-100">
      <Navbar />
      <div
        class="flex min-h-[80vh] flex-col justify-center px-6 py-12 lg:px-8"
        style={{ height: "80vh" }}
      >
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <img class="mx-auto h-20 w-auto" src={Logo} alt="Your Company" />
          <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-6" action="#" method="POST">
            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div class="mt-2">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div class="text-sm">
                  <a
                    href="#"
                    class="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div class="mt-2">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {message&&<>
            <p className="text-danger">{message}</p>
            </>}
            <div>
              <button
                onClick={handleLogin}
                type="submit"
                class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
          <p class="mt-10 text-center text-sm text-gray-500">
            Not a member?
            <a
              href="/contact"
              class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Reach out to the admin for help
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Login;
