import Calendar from "../../components/employee/Calendar";
import { useEffect, useState } from "react";
function Weeks({ anchorEl, setAnchorEl, handleCalWeek, handleClickCal, handleNextWeek, handlePreviousWeek, handleThisWeek, name, handleSelectEmployer, companies }) {
    
  return (
    <div className="row">
      <div className="col-lg-6  text-start ">
        <span class="btn-group me-3" role="group" aria-label="Basic outlined example">
          <button type="button" class="btn btn-outline-primary"> Weekly</button>
          <button type="button" class="btn btn-outline-primary">Summary</button>
        </span>
        {name && (
  <span className="dropdown">
    <button
      className="btn btn-secondary dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {name}
    </button>
    <ul className="dropdown-menu">
      {companies.map((company, index) => (
        <li key={index}>
          <a
            className="dropdown-item"
            href="#"
            onClick={() => handleSelectEmployer(company)}
          >
            {company.employerData.first_name + " " + company.employerData.last_name}
          </a>
        </li>
      ))}
    </ul>
  </span>
)}

      </div>
      <div className="col-lg-6">
        <div className="row">
          <div className="col-lg-12 w-100 text-lg-end mt-2 mt-lg-0 mt-md-0 pe-0">
            <span class="btn-group me-3" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-outline-primary" onClick={handleThisWeek}>
                This Week
              </button>
              <button type="button" class="btn btn-outline-primary d-flex align-items-center " onClick={handlePreviousWeek}>
                <span class="material-symbols-outlined ">
                  arrow_back_ios
                </span>
              </button>
              <button type="button" class="btn btn-outline-primary d-flex align-items-center " onClick={handleNextWeek}>
                <span class="material-symbols-outlined ">
                  arrow_forward_ios
                </span>
              </button>
              <button type="button" class="btn btn-outline-primary" onClick={handleClickCal}>
                <span class="material-symbols-outlined cal">
                  calendar_month
                </span>
              </button>
              <Calendar anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleCalWeek={handleCalWeek} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Weeks