import React from 'react';
import { GridToolbarContainer, GridRowModes } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from "@mui/icons-material/Add";

function EditToolbar({ setRows, setRowModesModel, currentWeek, name }) {

  const startDate = new Date(currentWeek.week_start);
  const endDate = new Date(currentWeek.week_end);
  const formatStart = startDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });
  const formatEnd = endDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

  const handleClick = () => {

    const placeholderId = Math.random().toString(36).substr(2, 9);


    setRows((oldRows) => [
      ...oldRows,
      {
        id: placeholderId,
        week: `${formatStart} - ${formatEnd}`,
        Sun: ' - ',
        Mon: ' - ',
        Tue: ' - ',
        Wed: ' - ',
        Thu: ' - ',
        Fri: ' - ',
        Sat: ' - ',
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [placeholderId]: { mode: GridRowModes.Edit, fieldToFocus: 'Sun' },
    }));
  };

  return (
    <GridToolbarContainer>
      {name !== "" && (
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record for Week of {formatStart} - {formatEnd}
        </Button>
      )}
    </GridToolbarContainer>
  );
}

export default EditToolbar;
