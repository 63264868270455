import { useState, useEffect, useRef } from "react";
import { Box, TextField } from "@mui/material";
import axios from "axios";
import { GridRowModes, DataGrid, GridRowEditStopReasons, useGridApiContext, } from "@mui/x-data-grid";
import PortalHeader from "../components/PortalHeader";
import EditToolbar from "../components/employee/EditToolbar";
import getColumns from "../utils/columnsUtils";
import { getCurrentWeek } from "../utils/dateUtils";
import { fetchTimeCards } from "../utils/fetchTimeCards";
import Weeks from "../components/employee/Weeks";

function TimeEditInputCell(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = useRef(null);

  const [startTime, endTime] = value.split(" - ");

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    const newValue = `${newStartTime} - ${endTime}`;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
      debounceMs: 200,
    });
  };

  const handleEndTimeChange = (event) => {
    const newEndTime = event.target.value;
    const newValue = `${startTime} - ${newEndTime}`;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
      debounceMs: 200,
    });
  };

  useEffect(() => {
    if (hasFocus && ref.current) {
      ref.current.querySelector("input").focus();
    }
  }, [hasFocus]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <TextField ref={ref} type="time" value={startTime} onChange={handleStartTimeChange} size="small" sx={{ mb: 0.2 }}
      />
      <TextField type="time" value={endTime} onChange={handleEndTimeChange} size="small"
      />
    </Box>
  );
}
function renderTimeRange(params) {
  const [start, end] = params.value.split(" - ");
  return <span>{`${start} - ${end}`}</span>;
}
const renderTimeRangeEditInputCell = (params) => {
  return <TimeEditInputCell {...params} />;
};

function Employee() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [employer, setEmployer] = useState([]);
  const employeeid = JSON.parse(localStorage.getItem("user"))._id;
  const [rows, setRows] = useState([]);
  const [newRowId, setNewRowId] = useState(null);
  const count = useRef(0);
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek(count.current));
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState("");

  const handleThisWeek = () => {
    count.current = 0;
    setCurrentWeek(getCurrentWeek(count.current));
  }
  const handleNextWeek = () => {
    count.current += 1;
    setCurrentWeek(getCurrentWeek(count.current));
  };

  const handlePreviousWeek = () => {
    count.current -= 1;
    setCurrentWeek(getCurrentWeek(count.current));
  };
  const handleCalWeek = (week) => {
    const today = new Date();
    const currentWeekStart = new Date(today);
    currentWeekStart.setUTCDate(today.getUTCDate() - today.getUTCDay());

    const selectedDate = new Date(week);
    const selectedWeekStart = new Date(selectedDate);
    selectedWeekStart.setUTCDate(selectedDate.getUTCDate() - selectedDate.getUTCDay());

    const msInAWeek = 7 * 24 * 60 * 60 * 1000;
    const weekOffset = Math.round((selectedWeekStart - currentWeekStart) / msInAWeek);
    count.current = weekOffset;
    setCurrentWeek(getCurrentWeek(count.current));
  };

  useEffect(() => {
    const fetchAndSetRows = async () => {
      const fetchrows = await fetchTimeCards(employeeid, "1");
      setRows(fetchrows)
    }
    fetchAndSetRows();
    const fetchCompanies = async () => {
      const response = await axios.get('/api/employees/assignedEmployees',{
        params:{employeeId: employeeid}
      })
      const {data} = response.data;
      setName(data[0]? data[0].employerData.first_name + " " +  data[0].employerData.last_name: name);
      setEmployer(data[0]? data[0]: []);
      setCompanies(data);
    }
     fetchCompanies();

  }, []);

  const handleSelectEmployer = (company) => {
    setEmployer(company);
    setName(company.employerData.first_name + " " +  company.employerData.last_name)
  };

  const handleClickCal = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  useEffect(() => {
    if (newRowId != null) { 
      const rowToSave = rows.find((row) => row.id === newRowId);
      if (rowToSave) {
        saveRow(rowToSave);
      }
      setNewRowId(null);
    }
  }, [rows]);



  const saveRow = async (row) => {
    try {
      const response = await axios.post("/api/timecards/createTimeCard", {
        id: employeeid,
        employer_id: companies[0].employer_id,
        week_start: currentWeek.week_start,
        week_end: currentWeek.week_end,
        daily_hours: parseDailyHours(row),
      }, {
        headers: { Authorization: localStorage.getItem("token") }
      });

      const newId = response.data.data._id;
      const updatedRow = { ...row, id: newId, isNew: false };
      setRows((oldRows) => oldRows.map((r) => (r.id === row.id ? updatedRow : r)));
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  const handleSaveClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    const rowToSave = rows.find((row) => row.id === id);
    if (rowToSave.isNew) {
      setNewRowId(id)
    }

  };


  const parseDailyHours = (row) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days.map((day) => {
      const [start_time, end_time] = row[day].split(" - ");
      return { day, start_time, end_time };
    });
  };

  const update = async (row) => {
    const dataToSend = {
      id: row.id,
      daily_hours: parseDailyHours(row),
    };
    try {
      await axios.put("/api/timecards/updateTimeCard", dataToSend,
        { headers: { Authorization: localStorage.getItem("token") } });
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    if (newRowId == null) {
      update(updatedRow);
    }

    return updatedRow;
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const columns = getColumns({
    rowModesModel,
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleDeleteClick,
    renderTimeRange,
    renderTimeRangeEditInputCell
  });
  return (
    <>
      <PortalHeader />
      <div style={{ height: "85%" }}>
        <div className="container h-100 pt-5">
          <Weeks anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleCalWeek={handleCalWeek} handleClickCal={handleClickCal} handleNextWeek={handleNextWeek} handlePreviousWeek={handlePreviousWeek} handleThisWeek={handleThisWeek} name={name} handleSelectEmployer={handleSelectEmployer} companies={companies}/>
          <div className=" mt-5" style={{ width: "100%" }}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                isCellEditable={(params) => params.row.id !== 2}

                slots={{
                  toolbar: (props) => (
                    <EditToolbar
                      {...props}
                    />
                  ),
                }}
                slotProps={{
                  toolbar: { setRows, setRowModesModel, currentWeek, name },
                }}
                style={{ height: 400, width: "100%", overflowX: "auto" }}
              />
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default Employee;
