import React, { useState, useEffect } from "react";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';

const CreateUser = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [role, setrole] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageSent, setMessageSent] = React.useState(false);
  const [employerData, setEmployerData] = React.useState([]);
  const [employer, setEmployer] = React.useState("");
  const [assignmentStartDate, setAssignmentStartDate] = React.useState("");
  const [assignmentEndDate, setAssignmentEndDate] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function getEmployers() {
    var res = await axios.get("/api/employers/getEmployers", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    const { data } = res;
    const employerData = Array.isArray(data.data) ? data.data : [data.data];
    setEmployerData(employerData);
  }

  useEffect(() => {
    getEmployers();
  }, []);

  const createUser = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      role,
      employer,
      assignmentStartDate,
      assignmentEndDate,
    };
    try {
      const response = await axios.post(
        "/api/admins/createUserWithAuth",
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.status === 201) {
        setMessageSent(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setrole("");
        setEmployer("");
        setAssignmentStartDate("");
        setAssignmentEndDate("");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section>
      {isLoading && (
        <LinearProgress />
      )}
      <div className="max-w-[1500px] m-auto min-w-[75%] p-6 h-[90vh] justify-center items-center flex">
        <form action="#" className="space-y-8">
          <div className="flex gap-4 max-w-[1500px] justify-between">
            <div className="w-full">
              <label htmlFor="firstname">First Name</label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                id="firstname"
                className="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="John"
                required
              />
            </div>
            <div className="w-full">
              <label htmlFor="lastname">Last Name</label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                id="lastname"
                className="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Doe"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="name@prairiejobs.ca"
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Password
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              name="password"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Password"
              required
            />
          </div>
          {/* <div>
            <label
              htmlFor="phoneNumber"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Phone number
            </label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="+1 630-496-7833"
              required
            />
          </div> */}
          <select
            value={role}
            onChange={(e) => setrole(e.target.value)}
            id="role"
            name="role"
            className="w-full py-2.5 text-gray-900 px-4 border-gray-300 bg-gray-50 focus:ring-primary-500 focus:border-primary-500 shadow-md"
          >
            <option value="default">Please select a role</option>
            <option value="employee">Employee</option>
            <option value="employer">Employer</option>
            <option value="admin">Admin</option>
          </select>
          {role === "employee" && (
            <div className="flex-col">
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Select Employer (optional)
                </label>
                <select
                  value={employer}
                  onChange={(e) => setEmployer(e.target.value)}
                  id="role"
                  name="role"
                  className="w-full py-2.5 text-gray-900 px-4 border-gray-300 bg-gray-50 focus:ring-primary-500 focus:border-primary-500 shadow-md"
                >
                  <option value="default">
                    Please select an employer (optional)
                  </option>
                  {employerData.map((employer) => {
                    return (
                      <option value={employer._id}>
                        {employer.first_name} {employer.last_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <div>
                  <label htmlFor="assignedDate">
                    Assignment Start Date (optional)
                  </label>
                  <input
                    value={assignmentStartDate}
                    onChange={(e) => {
                      setAssignmentStartDate(e.target.value);
                    }}
                    type="date"
                    name="assignedDate"
                    id="assignedDate"
                    className="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="John"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="assignedEndDate">
                    Assignment End Date (optional)
                  </label>
                  <input
                    value={assignmentEndDate}
                    onChange={(e) => {
                      setAssignmentEndDate(e.target.value);
                    }}
                    type="date"
                    name="assignedEndDate"
                    id="assignedEndDate"
                    className="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="John"
                    required
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-between items-center">
            <button
              onClick={createUser}
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 bg-indigo-600 mr-4"
            >
              Create User
            </button>
            <button
              onClick={() => (window.location.href = '/admin')}
              type="button"
              className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-gray-600 sm:w-fit hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300"
            >
              Back Admin Page
            </button>
          </div>
          {messageSent && (
            <div className="text-center">
              <p className="mt-10 text-center text-l text-green-500">
                User created successfully!
              </p>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default CreateUser;
