import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Employees from './pages/static/Employees';
import Home from './pages/static/Home';
import Admin from './pages/admin/Admin';
import TimeCard from './pages/admin/TimeCard';
import UserList from './pages/admin/UserList';
import Employee from './pages/Employee';
import Login from './pages/Login';
import NotFound from './pages/static/NotFound';
import Employer from './pages/Employer';
import Contact from './pages/static/Contact';

import { Auth0Provider } from "@auth0/auth0-react";
import { useContext, createContext, useState, useEffect } from "react";
import CreateUser from "./pages/CreateUser";
import About from "./pages/static/About";
import ResetPassword from "./pages/ResetPassword";
import Employers from "./pages/static/Employers";
import Industries from "./pages/static/Industries";

export const ContextProvider = createContext();

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(user);
      // console.log(user);
    }
    setLoading(false);
  }, []);

  const roleHomePage = {
    employee: '/employee',
    employer: '/employer',
    admin: '/admin',
  };

  const roleBasedRoute = (path, element, allowedRole) => {
    return (
      <Route
        path={path}
        element={
          user?.role === allowedRole
            ? element
            : <Navigate to={roleHomePage[user?.role] || '/'} replace />
        }
      />
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (

    <BrowserRouter>
      <ContextProvider.Provider value={{ user, setUser }}>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/employees' element={<Employees />} />
          <Route path='/employers' element={<Employers />} />
          <Route path='/industries' element={<Industries />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />

          {roleBasedRoute("/employee", <Employee />, "employee")}
          {roleBasedRoute("/employer", <Employer />, "employer")}
          {roleBasedRoute("/admin", <Admin />, "admin")}

          {roleBasedRoute("/create-user", <CreateUser />, "admin")}

          <Route path="/time" element={<TimeCard />} />
          <Route path="/user-list" element={<UserList />} />

          <Route path="/reset-password/:id" element={<ResetPassword />} />

          <Route path="*" element={<NotFound />} />
        </Routes>

      </ContextProvider.Provider>
    </BrowserRouter>
  );
}

export default App;
{
  /**
   *  <BrowserRouter>
  <ContextProvider.Provider value={{ user, setUser }}>
    <Auth0Provider
      domain="dev-94097o85.us.auth0.com"
      clientId="94097o85"
      redirectUri={window.location.origin}
    >
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/employees' element={<Employees />} />
        <Route path='/employers' element={<Employers />} />
        <Route path='/industries' element={<Industries />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />

        <Route path="/employee" element={<Employee />} />
        <Route path="/employer" element={<Employer />} />
        <Route path="/admin" element={<Admin />} />

        <Route path="/time" element={<TimeCard />} />
        <Route path="/user-list" element={<UserList />} />

        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/create-user" element={<CreateUser />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Auth0Provider>
  </ContextProvider.Provider>
</BrowserRouter>
   */
}