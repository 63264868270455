import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';

const getColumns = ({ rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick, renderTimeRange, renderTimeRangeEditInputCell }) => [
  { field: "week", headerName: "Week", width: 125, editable: false },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 75,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" sx={{ color: "primary.main" }} onClick={handleSaveClick(id)} />,
          <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
        ];
      }

      return [
        <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
        <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
      ];
    },
  },
  ...['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => ({
    field: day,
    headerName: day,
    width: 156,
    editable: true,
    renderCell: renderTimeRange,
    renderEditCell: renderTimeRangeEditInputCell,
  })),
];

export default getColumns;
