import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeBanner from "../../assets/HomeBanner.png";
import Admin from "../../assets/female-administrative.png";
import Customer from "../../assets/female-customer service2.jpg";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

function Employees() {
  return (
    <>
      <style>
        {`
          a.nav-link {
            color: black;
          }
           a.nav-link.active {
            font-weight: bold;
          } 
        .box_3 {
              display: block;
              height: 120px;
              position: absolute;
              left: 0;
              right: 0;
              width: 100%;
              transform: translateY(-20px);
              z-index: 1;
              box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
              background: #FFFFFF;
        }
          @media (min-width: 992px){
              .custom-spacing {
              margin-top: 200px;
              }
          }
        `}
      </style>
      <div style={{ height: '100vh' }} className="d-flex flex-column min-vh-100">
        <Header />
        <div className="text-center fw-bold py-2">
          Already an Employee?
          <a className="btn btn-outline-primary ms-2" href="/login" role="button">Sign in</a>
        </div>

        {/* Banner Section */}
        <div className="container-fluid d-lg-block mb-lg-5 py-5 text-center text-white" style={{ background: '#105DBC', backgroundImage: `url(${HomeBanner})`, backgroundSize: 'cover' }}>
          <div className="container position-relative">
            <h1 className='fw-light mb-4'>Career Opportunities With Top Employers In<p>Alberta, Saskatchewan, And Manitoba</p> </h1>
            <p className="fw-light fs-5">Whether you're looking for full-time or part-time work, temporary work, or direct hire, we have the right career waiting for you. Click below to find out what jobs are available!</p>
            <Link to="/contact" className="btn btn-primary fw-bold py-2 px-4">Find Work ➞</Link>
          </div>
        </div>

        {/* Additional Section */}
        <div className="container mt-5 pt-3 mb-5 pb-3">
          <div className="row">
            {/* Left Column for Image */}
            <div className="col-md-6 d-flex  align-items-center mb-3 mb-lg-0">
              <img
                src={Customer}
                alt="Descriptive Alt Text"
                className="img-fluid"
                style={{ maxHeight: '500px', objectFit: 'cover' }}
              />
            </div>

            {/* Right Column for Text */}
            <div className="col-md-6 d-flex align-items-center">
              <div>
                <h2 className="fw-bold">
                  Temporary, Temp-to-Hire & Direct-Hire Work For You
                </h2>
                <p className="fs-5">
                  PrairieJobs – your gateway to a world of job opportunities in the Canadian Prairies. Ready to get more experience? Earn a good paycheck? Build even better skills? From seasonal temp jobs to flexible assignments, we partner with outstanding companies. We have what you’re looking for in both full-time and part-time temporary and permanent employment.
                </p>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid" style={{ background: '#f4f6f9' }}>
          <div className="container mt-5 pt-3 mb-5 pb-3">
            <div className="row">
              {/* Column with text */}
              <div className="col-lg-6 pt-5 d-lg-block d-none">
                <div>
                  <h2 className="fw-bold mb-4">Work With The Best Staffing Agency!</h2>
                  <p className="fs-5">
                    A successful job search journey should be a 5-star experience. When you team up with PrairieJobs recruiters,
                    you’ll enjoy an unmatched, genuine, and transparent process — which is why 92% of job seekers highly recommend us.
                  </p>
                </div>
              </div>

              {/* Column with image */}
              <div className="col-lg-6 d-lg-flex justify-content-end d-none">
                <img src={Admin} className="img-fluid w-50" alt="Best Staffing Agency" />
              </div>
            </div>

            {/* Mobile-friendly version */}
            <div className="row d-lg-none">
              <div className="col-12">
                <img src={Admin} className="img-fluid w-100 mb-3" alt="Best Staffing Agency" />
              </div>
              <div className="col-12">
                <h2 className="fw-bold">Work With The Best Staffing Agency!</h2>
                <p className="fs-5">
                  A successful job search journey should be a 5-star experience. When you team up with PrairieJobs recruiters,
                  you’ll enjoy an unmatched, genuine, and transparent process — which is why 92% of job seekers highly recommend us.
                </p>
              </div>
            </div>
          </div>

        </div>

        {/* FAQ Section */}
        <div className="container mt-5 pt-5 mb-5 pb-3">
          <div><h1 className="fw-light mb-5">FAQ</h1></div>
          <Accordion className="py-2" defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className="fw-bold fs-4">Employee Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">How do I apply for a job with PrairieJobs?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You may come into our office to apply and hopefully interview OR you can contact us via phone at (306) 565-0789 or email at Contact@PrairieJobs.ca and we will schedule an interview with you.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">Does PrairieJobs charge any fees to its employees?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No! PrairieJobs does not charge our employees any fees.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">Are all PrairieJobs jobs temporary?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Most of our jobs are “temp-to-hire,” meaning they are intended to become regular,
                    ongoing positions after a certain period. PrairieJobs also hires for projects, which
                    tend to last for a specified duration.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">How should I dress when applying to the PrairieJobs office?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    All applicants should dress appropriately for the specific type of position that they want.
                    There are many occasions when an applicant will complete the interview with a Staffing Manager,
                    and then have the opportunity to either begin work immediately or have the opportunity to leave
                    our office and go for another interview with one of our clients.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">How much experience do I need to get a job?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Necessary experience depends upon the position you are applying for. Not all of our positions
                    require a specific skill set. Some offer opportunities for on-the-job training, while others
                    require verifiable and practical work experience.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What kinds of jobs does PrairieJobs have available?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    PrairieJobs places employees in various industries, such as production, construction labor,
                    industrial, manufacturing, administration, clerical, technical, and more.
                    See our full List of currently available jobs.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">Do I need to get a pre-employment drug screen?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    While PrairieJobs is a drug-free workplace based on federal guidelines, a pre-placement drug
                    screen may or may not be administered following certain employer guidelines as directed by
                    the client at the time of placement.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">What are PrairieJobs hours?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    PrairieJobs office hours are 8:00 AM – 5:00 PM, Monday through Friday.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="py-2">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography className="fw-bold fs-5">Once I am placed on assignment, how and when will I be paid?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    To eliminate payday hassles for our employees, PrairieJobs offers two convenient ways to receive
                    their paychecks. We offer direct deposit into your personal bank account or onto a Pay Card
                    that we will issue. Payroll is run on a weekly basis, and you will get paid the week following.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* Add other FAQ accordions here as needed */}
            </AccordionDetails>
          </Accordion>
        </div>

        {/* Additional Section */}


        <Footer />
      </div>
    </>
  );
}

export default Employees;
