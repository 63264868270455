import { useState, useEffect } from "react";
import axios from "axios";
import Chip from '@mui/material/Chip';
function Dropdown({ id, initialstatus }) {
    const [status, setStatus] = useState(initialstatus);
    console.log(id);
    const handleClick = async (verification) => {
        setStatus(verification);
        await axios.put('/api/timecards/verify', {
            id: id,
            status: verification
        })

    }
    return (
        <>
            <div className="d-flex">
                <Chip label={status} color={
                    status === 'pending'
                        ? 'warning'
                        : status === 'denied'
                            ? 'error'
                            : status === 'revising'
                                ? 'info'
                                : status === 'approved'
                                    ? 'success'
                                    : 'pending'
                } variant="outlined" />
                <div className="dropdown ms-5">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Verify Time Card
                    </button>
                    <ul class="dropdown-menu">
                        <li className="cursor-pointer"><a class="dropdown-item" onClick={() => { handleClick('approved') }}>Approve</a></li>
                        <li className="cursor-pointer"><a class="dropdown-item" onClick={() => { handleClick('denied') }}>Deny</a></li>
                        <li className="cursor-pointer"><a class="dropdown-item" onClick={() => { handleClick('revising') }}>Request Revision</a></li>
                    </ul>
                </div>

            </div>

        </>
    )
}

export default Dropdown